import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import blogStyles from "./blog.module.scss"
const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1 className={blogStyles.newTitle}>Contact</h1>
      <p className={blogStyles.newTitle}>
        The best way to reach me is via{" "}
        <a
          className={blogStyles.newTitle}
          href="https://twitter.com/@aliraam"
          target="_blank"
        >
          @aliraam
        </a>{" "}
        on Twitter!
        <br />
        also you can contact via mail: alipirani on{" "}
        <a
          className={blogStyles.newTitle}
          href="https://outlook.com"
          target="_blank"
        >
          outlook
        </a>
      </p>
    </Layout>
  )
}

export default ContactPage
